export const reviews = [
  {
    id: 4,
    name: "Vladyslava",
    rating: 5,
    text: "Un service impeccable et une attention aux détails inégalée !",
    avatar: "https://i.pravatar.cc/150?img=35", // Male Avatar
  },
  {
    id: 5,
    name: "Sophie",
    rating: 5,
    text: "Des conseils professionnels et un résultat qui dépasse les attentes !",
    avatar: "https://i.pravatar.cc/150?img=7", // Female Avatar
  },
  {
    id: 6,
    name: "Vladyslav",
    rating: 5,
    text: "Ambiance chaleureuse et travail de pro. Je recommande à 100% !",
    avatar: "https://i.pravatar.cc/150?img=11", // Male Avatar (based on assumption)
  },
  {
    id: 7,
    name: "Émilie",
    rating: 5,
    text: "Je suis venue pour une coupe, et je repars avec une œuvre d'art !",
    avatar: "https://i.pravatar.cc/150?img=10", // Female Avatar
  },
  {
    id: 8,
    name: "Tania",
    rating: 5,
    text: "Expérience exceptionnelle, je reviendrai sans hésiter !",
    avatar: "https://i.pravatar.cc/150?img=41", // Male Avatar
  },
  {
    id: 9,
    name: "Clémentine",
    rating: 5,
    text: "Le meilleur salon de la ville. Mon rendez-vous est toujours un plaisir !",
    avatar: "https://i.pravatar.cc/150?img=42", // Female Avatar
  },
  {
    id: 10,
    name: "Florian",
    rating: 5,
    text: "Rapide, efficace, et toujours avec le sourire !",
    avatar: "https://i.pravatar.cc/150?img=68", // Male Avatar
  },
  {
    id: 11,
    name: "Charlotte",
    rating: 5,
    text: "La qualité du service est constante à chaque visite.",
    avatar: "https://i.pravatar.cc/150?img=26", // Female Avatar
  },
  {
    id: 12,
    name: "Anna",
    rating: 5,
    text: "Un résultat toujours à la hauteur de mes attentes !",
    avatar: "https://i.pravatar.cc/150?img=24", // Male Avatar
  },
  {
    id: 13,
    name: "Lucie",
    rating: 5,
    text: "Un savoir-faire incroyable. Toujours satisfaite.",
    avatar: "https://i.pravatar.cc/150?img=27", // Female Avatar
  },
  {
    id: 14,
    name: "Olivier",
    rating: 5,
    text: "Un salon où l'on se sent comme chez soi. Merci pour ces bons moments.",
    avatar: "https://i.pravatar.cc/150?img=33", // Male Avatar
  },
  {
    id: 15,
    name: "Céline",
    rating: 5,
    text: "Chaque visite est un moment de détente et de plaisir.",
    avatar: "https://i.pravatar.cc/150?img=38", // Female Avatar
  },
  {
    id: 16,
    name: "Loïc",
    rating: 5,
    text: "Toujours à l'écoute et des conseils au top. Merci !",
    avatar: "https://i.pravatar.cc/150?img=68", // Male Avatar
  },
];

export const presentationCards = [
  {
    icon: "mdi-scissors-cutting",
    iconColor: "pink",
    title: "Maître professionnel",
    subtitle: "J'ai suivi de nombreux cours et formations.",
    color: "purple",
    aosAnimation: "fade-up",
  },
  {
    icon: "mdi-clock-time-four-outline",
    iconColor: "yellow",
    title: "Service rapide",
    subtitle: "Je valorise votre temps.",
    color: "deep-purple",
    aosAnimation: "fade-up",
  },
  {
    icon: "mdi-account-star",
    iconColor: "blue",
    title: "Avis des clients",
    subtitle: "Consultez les avis de mes clients satisfaits.",
    color: "indigo",
    aosAnimation: "fade-up",
  },
  {
    icon: "mdi-star-face",
    iconColor: "purple",
    title: "26+ ans d'expérience",
    subtitle:
      "De nombreuses années d'expérience garantissent la haute qualité de mes services.",
    color: "teal",
    aosAnimation: "fade-up",
  },
  {
    icon: "mdi-earth",
    iconColor: "green",
    title: "Approche européenne",
    subtitle: "Technologies modernes et meilleures pratiques.",
    color: "cyan",
    aosAnimation: "fade-up",
  },
  {
    icon: "mdi-chair-rolling",
    iconColor: "orange",
    title: "Fauteuils confortables",
    subtitle: "Confort pendant la coupe de cheveux.",
    color: "amber",
    aosAnimation: "fade-up",
  },
];

export const services = [
  {
    id: 1,
    title: "Coupe de femme",
    price: "À partir de 35$",
    imgSrc: require("@/assets/variant_price/women_photo.png"),
  },
  {
    id: 2,
    title: "Coupe d'enfant",
    price: "À partir de 25$",
    imgSrc: require("@/assets/variant_price/child_photo.png"),
  },
  {
    id: 3,
    title: "Coupe d'homme",
    price: "À partir de 30$",
    imgSrc: require("@/assets/variant_price/men_photo.png"),
  },
];

export const galleryItems = [
  {
    src: require("@/assets/assets_hair/coupe_f_1.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_f_3.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_f_4.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_f_5.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_f_6.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_f_7.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_f_8.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_f_9.jpg"),
    text: "coupe de femme",
  },
  {
    src: require("@/assets/assets_hair/coupe_h_1.jpg"),
    text: "coupe d'homme",
  },
  {
    src: require("@/assets/assets_hair/coupe_h_2.jpg"),
    text: "coupe d'homme",
  },
  {
    src: require("@/assets/assets_hair/coupe_h_3.jpg"),
    text: "coupe d'homme",
  },
  {
    src: require("@/assets/assets_hair/coupe_e_1.jpg"),
    text: "coupe d'enfant",
  },
];

<!-- src/App.vue -->

<template>
  <v-app>
    <AppNavbar />
    <HomePage />
    <AppFooter></AppFooter>
  </v-app>
</template>

<script>
import AppNavbar from "./components/AppNavbar.vue";
import HomePage from "./components/HomePage.vue";
import AppFooter from "./components/AppFooter.vue";
export default {
  components: {
    AppNavbar,
    HomePage,
    AppFooter,
  },
  name: "App",
};
</script>

<template>
  <div id="app" class="app-navbar">
    <v-app-bar
      app
      fixed
      color="rgba(255, 255, 255, 0.75)"
      dark
      elevate-on-scroll
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="mr-5">Anna & Coiffure</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        text
        class="d-none d-sm-flex nav-btn"
        v-for="item in items"
        :key="item.title"
        @click="handleNavigation(item.link)"
      >
        <v-icon left>{{ item.icon }}</v-icon> {{ item.title }}
        <div class="nav-btn-underline"></div>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="handleNavigation(item.link)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    image: "https://picsum.photos/1920/1080?random",
    drawer: false,
    items: [
      { title: "Services", link: "services", icon: "mdi-scissors-cutting" },
      { title: "Contacts", link: "contacts", icon: "mdi-map-marker" },
      {
        title: "Mes travaux",
        link: "myWorks",
        icon: "mdi-folder-multiple-image",
      },
      {
        title: "Nos avantages",
        link: "advantages",
        icon: "mdi-trophy-outline",
      },
    ],
  }),
  methods: {
    handleNavigation(link) {
      this.drawer = false;
      const section = document.getElementById(link);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
/* Navbar Styling */
.app-navbar {
  background-color: var(--app-background-color);
  color: var(--app-text-color);
}

/* Button Transitions */
.v-btn {
  transition: transform 0.3s ease;
  position: relative;
}

.v-btn:hover {
  transform: scale(1.1);
}

.nav-btn .nav-btn-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: currentColor;
  transition: width 0.3s ease;
}

.v-btn:hover .nav-btn-underline {
  width: 100%;
}

/* ListItem Transitions */
.v-list-item {
  transition: background-color 0.3s ease;
}

.v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Color Variables */
:root {
  --app-background-color: white;
  --app-text-color: black;
}

.dark {
  --app-background-color: black;
  --app-text-color: white;
}

/* Media Queries */
@media (min-width: 576px) {
  .d-sm-none {
    display: none;
  }

  .d-none.d-sm-flex {
    display: flex;
  }
}
</style>

<template>
  <div class="footer">
    <v-container>
      <v-row>
        <v-col md="4" class="footer-section">
          <h4>À propos de nous</h4>
          <p>
            Je propose les meilleurs services de coiffure dans votre ville. La
            qualité et la satisfaction du client sont ma priorité principale.
          </p>
        </v-col>

        <v-col md="4" class="footer-section">
          <h4>Contacts</h4>
          <ul>
            <li><v-icon small>mdi-phone</v-icon> +1 438 399 0771</li>
            <li><v-icon small>mdi-email</v-icon>annagagne2013@outlook.com</li>
            <li>
              <v-icon small>mdi-map-marker</v-icon> 634 rue Cavelier, G1X 3H5
              Québec
            </li>
          </ul>
        </v-col>

        <v-col md="4" class="footer-section">
          <h4>Suivez-nous</h4>
          <v-row class="social-icons-row">
            <v-col
              v-for="(link, index) in socialLinks"
              :key="index"
              class="mx-auto"
            >
              <v-btn
                icon
                :href="link.url"
                target="_blank"
                rel="noopener noreferrer"
                class="social-icon-btn"
              >
                <v-icon large :class="link.animation">{{ link.icon }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="divider-style"></v-divider>

      <p class="footer-copyright">
        © 2023 Anna & Coiffure. Tous droits réservés. <br />Réalisé par
        <a href="https://www.linkedin.com/in/vladyslav-gagne-38ba91297/"
          ><b>Vladyslav Gagné</b></a
        >
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    socialLinks: [
      {
        url: "https://www.instagram.com/salon_annacoiffure/?fbclid=IwAR1hJskl0Og_yc8_QwLVpdaoKsYXi2ndnKgYSMgTb9ab05IE64K56NItKPc",
        icon: "mdi-instagram",
        animation: "social-icon-hover",
      },
      {
        url: "https://www.facebook.com/profile.php?id=100090459918886",
        icon: "mdi-facebook",
        animation: "social-icon-hover",
      },
      {
        url: "#",
        icon: "mdi-twitter",
        animation: "social-icon-hover",
      },
      {
        url: "#",
        icon: "mdi-linkedin",
        animation: "social-icon-hover",
      },
    ],
  }),
  name: "AppFooter",
};
</script>

<style scoped>
.footer {
  background-color: #3f51b5;
  color: #ffffff;
  padding: 30px 0;
}

.footer h4 {
  margin-bottom: 20px;
  font-weight: 500;
  border-bottom: 2px solid #ffffff;
  display: inline-block;
  padding-bottom: 8px;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer ul li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.footer ul li v-icon {
  margin-right: 10px;
}

.footer-section {
  margin-bottom: 30px;
}

.footer-copyright {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.social-icons-row {
  justify-content: center;
}

.social-icon-btn {
  background-color: #ffffff;
  border-radius: 50%;
  color: #3f51b5;
  margin: 0 10px;
}

.social-icon-btn:hover {
  background-color: #f5f5f5;
  transform: scale(1.1);
}

.divider-style {
  margin: 20px 0;
  background-color: #ffffff;
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueScrollTo from "vue-scrollto";
import { loadFonts } from "./plugins/webfontloader";

loadFonts();

const app = createApp(App);
app.use(vuetify);
app.use(VueScrollTo);
app.mount("#app");

<template>
  <div class="background-color">
    <!-- Главная страница -->
    <v-parallax
      src="../assets/background.png"
      height="500"
      width="100%"
      style="
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 65px;
      "
    >
    </v-parallax>

    <v-spacer class="my-5"></v-spacer>

    <v-container>
      <!-- Фото стрижек -->
      <section id="services" class="my-10">
        <h2>Nos Services</h2>
        <v-row>
          <v-col v-for="service in services" :key="service.id" cols="12" md="4">
            <v-card>
              <v-img :src="service.imgSrc" aspect-ratio="1.7"></v-img>
              <v-card-title>{{ service.title }}</v-card-title>
              <v-card-subtitle>{{ service.price }}</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <v-spacer class="my-5"></v-spacer>

      <!-- Carte -->
      <section id="contacts" class="my-10">
        <h2 data-aos="fade-up" data-aos-delay="100">Contacts</h2>
        <v-row class="mb-5">
          <!-- Carte -->
          <v-col :md="7" :cols="12">
            <div
              id="mapid"
              style="height: 400px"
              data-aos="fade-right"
              data-aos-delay="200"
            ></div>
          </v-col>

          <!-- Informations de contact -->
          <v-col :md="5" :cols="12">
            <v-card class="pa-4" data-aos="fade-left" data-aos-delay="300">
              <v-card-title class="headline"
                >Informations de Contact</v-card-title
              >

              <v-card-subtitle class="mb-4">
                <v-icon left>mdi-map-marker</v-icon>
                <a
                  :href="'https://www.google.com/maps/dir/?api=1&destination=634+rue.Cavelier+G1X+3H5'"
                  target="_blank"
                  >634 rue.Cavelier G1X 3H5</a
                >
              </v-card-subtitle>

              <!-- Téléphone -->
              <v-card-subtitle class="mb-4">
                <v-icon left>mdi-phone</v-icon> +438 399 0771
              </v-card-subtitle>

              <!-- Facebook -->
              <v-card-subtitle class="mb-4">
                <v-icon left>mdi-facebook</v-icon>
                <a
                  :href="'https://www.facebook.com/anna.gagne.583'"
                  target="_blank"
                  >Anna Gagné</a
                >
              </v-card-subtitle>

              <v-card-text>
                <h3>Horaires de travail :</h3>

                <v-row>
                  <!-- Première colonne des jours -->
                  <v-col cols="6">
                    <dl>
                      <!-- Lundi, Mardi, Mercredi, Jeudi -->
                      <div
                        :class="{ 'current-day': isCurrentDay('Monday') }"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <dt>Lundi :</dt>
                        <dd>8:00 - 20:00</dd>
                      </div>
                      <div
                        :class="{ 'current-day': isCurrentDay('Tuesday') }"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <dt>Mardi :</dt>
                        <dd>8:00 - 20:00</dd>
                      </div>
                      <div
                        :class="{ 'current-day': isCurrentDay('Wednesday') }"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <dt>Mercredi :</dt>
                        <dd>8:00 - 20:00</dd>
                      </div>
                      <div
                        :class="{ 'current-day': isCurrentDay('Thursday') }"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <dt>Jeudi :</dt>
                        <dd>8:00 - 20:00</dd>
                      </div>
                    </dl>
                  </v-col>

                  <!-- Deuxième colonne des jours -->
                  <v-col cols="6">
                    <dl>
                      <!-- Vendredi, Samedi, Dimanche -->
                      <div
                        :class="{ 'current-day': isCurrentDay('Friday') }"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <dt>Vendredi :</dt>
                        <dd>8:00 - 20:00</dd>
                      </div>
                      <div
                        :class="{ 'current-day': isCurrentDay('Saturday') }"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <dt>Samedi :</dt>
                        <dd>8:00 - 17:00</dd>
                      </div>
                      <div
                        :class="{ 'current-day': isCurrentDay('Sunday') }"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <dt>Dimanche :</dt>
                        <dd>Fermé</dd>
                      </div>
                    </dl>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <v-spacer class="my-5"></v-spacer>

      <section id="myWorks" class="my-10">
        <h2>Mes Travaux:</h2>
        <v-row>
          <v-col
            v-for="(item, index) in galleryItems"
            :key="index"
            cols="12"
            md="4"
          >
            <v-card
              class="photo-card"
              @click="currentImage = item.src"
              data-aos="fade-up"
              :data-aos-delay="index * 100"
            >
              <v-img :src="item.src" aspect-ratio="1.7" class="scaled-image">
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <div class="overlay">
                <div class="text">{{ item.text }}</div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!-- Модальное окно для отображения фотографии на полный экран -->
        <v-dialog v-model="currentImage" width="40%">
          <v-img :src="currentImage" contain></v-img>
        </v-dialog>
      </section>

      <v-spacer class="my-5"></v-spacer>

      <section id="advantages" class="my-10">
        <h2>Nos avantages</h2>
        <v-row justify="center">
          <v-col
            v-for="(card, index) in presentationCards"
            :key="index"
            cols="12"
            md="4"
            :data-aos="card.aosAnimation"
            :data-aos-delay="index * 200 + 100"
          >
            <v-card
              :color="card.color"
              class="pa-4 text-center"
              dark
              elevation="6"
            >
              <v-icon :color="card.iconColor" large>{{ card.icon }}</v-icon>
              <v-card-title class="font-weight-bold">{{
                card.title
              }}</v-card-title>
              <v-card-subtitle>{{ card.subtitle }}</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <v-spacer class="my-5"></v-spacer>

      <!-- Отзывы -->
      <v-row id="reviews">
        <v-col>
          <h2 data-aos="fade-up">Avis des clients</h2>
          <v-carousel
            cycle
            height="300"
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover
            class="reviews-carousel"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <v-carousel-item v-for="review in reviews" :key="review.id">
              <v-card
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="300"
              >
                <v-row no-gutters>
                  <v-col cols="2" class="d-flex justify-center align-center">
                    <v-avatar size="90">
                      <img :src="review.avatar" alt="User Image" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="10">
                    <v-card-title>
                      <span class="review-name">{{ review.name }}</span>
                      <v-rating
                        v-model="review.rating"
                        half-increments
                        readonly
                      ></v-rating>
                    </v-card-title>
                    <v-card-text>{{ review.text }}</v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <!-- Статистика -->
      <v-row align="center">
        <v-col class="text-center">
          <h4>Note moyenne de Google: {{ averageRating }} sur 5</h4>
          <span>Basé sur {{ reviews.length }} avis</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import L from "leaflet";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  reviews,
  presentationCards,
  services,
  galleryItems,
} from "../../public/data/data.js";
export default {
  data() {
    return {
      currentImage: null,
      galleryItems,
      services,
      reviews,
      presentationCards,
      isToday(dayName) {
        const today = new Date().toLocaleString("en-US", { weekday: "long" });
        return dayName === today;
      },
    };
  },
  mounted() {
    this.initMap();
    AOS.init({
      duration: 1000, // You can also change the duration of the animations
    });
  },
  computed: {
    averageRating() {
      if (!this.reviews.length) return 0;

      let totalRating = this.reviews.reduce(
        (accumulator, review) => accumulator + review.rating,
        0
      );
      return (totalRating / this.reviews.length).toFixed(1);
    },
  },
  methods: {
    blurSlides() {
      this.reviews.forEach((review, index) => {
        review.isActive = this.$refs.mySwiper[0].swiper.activeIndex === index;
      });
    },
    isCurrentDay(weekday) {
      const today = new Date().toLocaleDateString("en-US", { weekday: "long" });
      return today === weekday;
    },
    initMap() {
      const map = L.map("mapid").setView(
        [46.772640481397154, -71.32519401534277],
        16
      );

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      // Установка иконки маркера по умолчанию
      let defaultIcon = L.icon({
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        iconAnchor: [12, 41],
      });

      L.Marker.prototype.options.icon = defaultIcon;

      // Создание маркера с установленной иконкой
      L.marker([46.772640481397154, -71.32519401534277])
        .addTo(map)
        .bindPopup("Salon de coiffure Anna")
        .openPopup();

      this.$nextTick(() => {
        map.invalidateSize();
      });
    },
  },
};
</script>

<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "aos/dist/aos.css";
.scaled-image {
  transform: scale(1.7);
}
.photo-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.photo-card:hover {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  opacity: 0;
}

.photo-card:hover .overlay {
  height: 100%;
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

h1,
h2,
.v-card-text,
.review-name {
  transition: transform 0.3s ease;
}

.v-card:hover {
  transform: translateY(-5px); /* Подпрыгивание */
  transition: all 0.3s ease; /* Плавный переход */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonials {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.v-card {
  text-align: center;
  transition: transform 0.3s;
}

.v-rating {
  display: inline-block;
  margin-top: 10px;
}

h1,
h2 {
  padding-bottom: 20px;
}

.v-btn {
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.v-btn:hover {
  background-color: #ff4081;
}

.v-card,
.v-carousel-item {
  transition: transform 0.3s ease;
}

.v-card {
  transition: box-shadow 0.3s ease;
}

.testimonials {
  margin: 30px;
}

.v-card {
  transition: transform 0.3s;
}

.v-card-title {
  display: flex;
  justify-content: space-between;
}

.v-card-title span {
  font-weight: bold;
}

:root {
  --home-page-background-color: white;
  --home-page-text-color: black;
}

.dark {
  --home-page-background-color: black;
  --home-page-text-color: white;
}
body {
  margin: 0;
  padding: 0;
}

/* Добавьте это в ваш <style> тег */
.current-day dt,
.current-day dd {
  text-decoration: underline;
}
#reviews {
  margin-bottom: -120px;
}

#mapid {
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
</style>
